/* WatchlistForm.module.css */
.form {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increase space between form items for a more airy layout */
  width: 100%; /* Use the full width of the modal content area */
}

.dropdown {
  padding: 12px; /* Slightly larger padding for a more touch-friendly UI */
  border-radius: 6px; /* Smoother border radius */
  background-color: #181818; /* Dark grey background, typical of Spotify */
  color: #fff; /* White text for high contrast */
  border: 1px solid #3e3e3e; /* Subtle border for depth */
  font-size: large;
}

.addButton, .cancelButton {
  padding: 12px 25px; /* Larger buttons are easier to interact with */
  border: none;
  border-radius: 500px; /* Very rounded edges, similar to Spotify's button style */
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase; /* Uppercase text for buttons */
  letter-spacing: 1px; /* Increased spacing for a more premium feel */
  font-size: 0.9em; /* Slightly smaller font-size for uppercase text */
  margin-right:12px;
}

.addButton {
  background-color: #72efdc87; /* Spotify's brand color for primary buttons */
  color: white;
  margin-right:1em;
}

.addButton:hover {
  background-color: #72EFDD; /* A lighter shade for hover effect */
}

.cancelButton {
  background-color: #b3b3b3; /* Neutral grey for cancel, less emphasis */
  color: #191414; /* Almost black text for contrast */
  margin-left: auto; /* Push the cancel button to the right */
}

.cancelButton:hover {
  background-color: #d3d3d3; /* A lighter shade for hover */
} 


.modalContent {
  background-color: #282828; /* Slightly lighter grey for the modal background */
  padding: 40px; /* More padding for a spacious feel */
  border-radius: 8px;
  width: 30vw; /* Use viewport width for a consistent size regardless of screen size */
  max-width: 500px; /* Max width to prevent overly large modals on big screens */
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
  display: flex; /* Flex to help with internal alignment */
  flex-direction: column; /* Stack children vertically */
  align-items: stretch; /* Stretch children to fit width */
}