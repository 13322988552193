/* timeframeDropdown.module.css */
.dropdownContainer {
    margin-bottom: 0px;
}
  
  .dropdown {
    padding: 0.5rem 1rem;
    width: min-content;
    height:min-content;
    background-color: #181818; 
    color: #72EFDD; 
    border: 2px solid #72EFDD; 
    border-radius: 1rem; 
    font-family: 'Circular', 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    font-size: 1em;
    cursor: pointer;
  }
  
  .dropdown:focus {
    outline: none;
    box-shadow: 0 0 0 2px #72EFDD; 
  }
    