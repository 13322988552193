/* chart.module.css */
.chartContainer {
  position: relative;
  background-color: #181818;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
  overflow: hidden; /* Prevents content from overflowing */
}

.chartCanvas {
  height:100%;
}

.tooltip {
  background-color: #282828; 
  color: #fff;
  border-radius: 4px; 
  padding: 10px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.4); 
}

@media (max-width: 768px) {
  .chartContainer {
    padding: 10px;
  }
}