/* Watchlist.module.css */
.watchlistContainer {
  background-color: #181818; /* Spotify dark theme background color */
  border-radius: 4px; /* Soften the corners */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);  /* Primary shadow */
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling when needed */
}

/* Add a hover class to the rows */
.watchlistContainer tr:hover {
  background-color: #3a3a3a; /* Slightly lighter than the selected state */
}

.selectedAssetRow {
  background-color: #333; /* Or any other color that indicates selection */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border-bottom: 1px solid #333; /* Subtle borders between rows */
  padding: 12px; /* Spacing inside cells */
  text-align: left;
  vertical-align: middle;
}

.table th {
  background-color: #282828; /* Slightly lighter for contrast */
  color: #fff;
  font-weight: normal; /* Normal font weight for Spotify style */
}

.addAssetRow {
  color: #72EFDD; /* Grey text */
  text-align: center;
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid #333; /* Top border to separate from the list */
}

.addAssetText {
  color: #888; /* initial color */
  transition: color 0.3s; /* smooth color transition */
}

.addAssetForm {
  display: flex;
  justify-content: space-around;
  padding: 12px;
}

.addAssetRow:hover .addAssetText {
  color: #66dde6b5; /* color when hovered */
}

.input {
  border: 1px solid #333;
  border-radius: 4px;
  padding: 8px;
  color: grey; /* Grey text for input */
  background-color: #181818; /* Match the card background */
  margin-right: 8px; /* Spacing between fields */
}


.addButton {
  background-color: #66dde6b5; /* Your brand's blue/teal color */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 500px;
  cursor: pointer;
}

.cancelButton {
  background-color: red; /* Red background for cancel button */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 500px;
  cursor: pointer;
}

.addButton:hover {
  background-color: #72efdc87; /* Slightly darker or lighter for hover*/
}

.cancelButton:hover {
  background-color: rgba(255, 0, 0, 0.618); /* Slightly darker or lighter for hover*/
}

.indicator {
  cursor: pointer;
  display: inline-block; /* Align with text */
  margin-right: 10px; /* Spacing to the right */
}

.indicator.active {
  color: #1DB954; /* Highlight active state with Spotify green */
}

.indicatorLight {
  width: 20px;
  height: 20px;
  display: inline-block; /* Align with text */
  margin: auto; /* Center in cell if larger */
}

/* Style the scrollbar track */
.watchlistContainer::-webkit-scrollbar-track {
  background-color: #181818; /* Dark background as per Spotify theme */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Style the scrollbar thumb */
.watchlistContainer::-webkit-scrollbar-thumb {
  background-color: #535353; /* A shade of grey for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

/* Style the scrollbar itself */
.watchlistContainer::-webkit-scrollbar {
  width: 8px; /* Narrower scrollbar width */
  background-color: #181818; /* Dark background as per Spotify theme */
}

/* Optional: Style for scrollbar thumb hover */
.watchlistContainer::-webkit-scrollbar-thumb:hover {
  background-color: #636363; /* A lighter shade of grey for hover effect */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .watchlistContainer {
    width: 100%; /* Full width on small screens */
    /*margin-bottom: 20px;*/
  }
}
