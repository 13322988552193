.footer {
  background-color: #181818; 
  color: #FFFFFF;
  text-align: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 14px; 
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.5); 
}

.footer a {
  color: #FFFFFF;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease; 
}

.footer a:hover {
  color: #1DB954; 
}

.smallPrint {
  display: block; 
  margin-top: 5px; 
  font-size: 12px; 
  opacity: 0.7; 
}

@media (max-width: 768px) {
  .footer {
      position: relative; 
      padding: 10px; 
  }

  .footer a {
      display: block; 
      margin-bottom: 5px; 
  }
}
