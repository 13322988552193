/* dashboard.module.css */
.dashboardContainer {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Uniform padding around the dashboard container */
  gap: 20px; /* Uniform gap between inner flex items */
  height: 100%;
}

.dashboardContent {
  display: flex;
  flex: 1; /* Fill available space */
  overflow-y:auto;
  height: 100%;
}

.placeholder {
  color: #888888; /* Light gray text color similar to Spotify's placeholder text */
  background-color: #181818; /* Card background color */
  border-radius: 4px; /* Match card border radius */
  text-align: center;
  padding: 40px 20px; /* More padding to make it look like a large empty card */
  font-size: 1rem; /* Optional: Adjust font size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Take full height of the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);  /* Primary shadow */
}

.rightColumn {
  width: 100%; /* Width of the right column */
  height:100%;
  flex-direction: column;
  flex:1;
  overflow-y: auto; /* Make right column scrollable */
  overflow-x: hidden;
  gap: 20px; /* Uniform gap between inner flex items */
}

.leftColumn {
  width: 40%; /* Width of the left column */
  gap: 20px; /* Uniform gap between inner flex items */
  overflow-y: auto; /* Make right column scrollable */
  overflow-x:hidden;
  height:100vh;
}

.cardStyle { 
  background-color: #181818;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);  /* Primary shadow */
  flex-direction: column;
  padding: 20px; 
  margin-bottom: 20px; 
}

.chartCardStyle {
  height: min-content; 
  padding: 20px;
  background-color: #181818;
  border-radius: 4px;
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);  /* Primary shadow */
  margin-bottom: 20px; 
  flex-direction: column;
}

.headerLabel {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 20px; 
}

.watchlistContainer .selectedAssetRow {
  background-color: #333333; 
}

@media (max-width: 768px) {
  .dashboardContent {
    flex-direction: column;
    margin-bottom: 20px; 

  }

  .leftColumn,
  .rightColumn {
    flex: 1; 
    margin-bottom: 20px; 

  }
  
  .cardStyle,
  .chartCardStyle {
    margin-bottom: 20px; 
  }
}
