/* Navbar.module.css */
.navbar {
    background-color: #181818; 
    color: #FFFFFF;
    padding: 16px 0; 
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); 
}

.brand {
    font-size: 1.5em; 
    padding-left: 20px; 
    font-weight: bold; 
}

.navLinks {
    list-style: none;
    display: flex;
    align-items: center;
    padding-right: 20px; 
}

.navLinks li {
    padding: 0 16px; 
}

.navLinks li a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 1em; 
    transition: color 0.3s ease; 
}

.navLinks li a:hover {
    color: #72EFDD ; 
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column; 
    }

    .brand {
        margin-bottom: 10px; 
    }
}
