.frameworkContainer {
    padding: 20px;
    background: #181818;
    color: #fff;
    display: flex; 
    flex-direction: column; 
    align-items: left;
    justify-content: center;
}
  
  .header {
    margin-bottom: 20px;
  }
  
  .frameworkSelect,
  .timeframeSelect {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #000;
    color: #72EFDD;
    border: 1px solid #72EFDD;
    border-radius: 4px;
  }
  
  .inputField {
    width: calc(100% - 20px); /* Padding */
    padding: 10px;
    margin-bottom: 20px;
    background-color: #000;
    color: #fff;
    border: 1px solid #333;
    border-radius: 4px;
  }
  
  .label {
    display: block;
    margin-bottom: 10px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .saveButton,
  .resetButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
  }
  
  .saveButton {
    background-color: #72efdc87;
  }
  
  .resetButton {
    background-color: #f50;
  }
  
  .selectMultiple {
    background: #0d0d0d;
    color: #fff;
    border: 1px solid #333;
  }
  
  select {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #181818;
    color: #72EFDD;
  }