/* IndicatorSettings.module.css */
.indicatorSettingsContainer {
  border: 1px solid #333; 
  border-radius: 4px; 
  overflow: hidden; 
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5); 
}

.tabs,
.subTabs {
  display: flex;
  background-color: #181818; 
}

.tabs button,
.subTabs button {
  flex: 1;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  border: none;
  border-bottom: 2px solid transparent; 
}

.tabs button:hover,
.subTabs button:hover {
  border-bottom: 2px solid #aaa; 
}

.activeTab,
.activeSubTab {
  border-bottom: 2px solid #1db954; 
}

.tabContent {
  padding: 20px;
  color: #fff;
}

.tabs button:last-child,
.subTabs button:last-child {
  border-right: none;
}

