/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Spotify's font style */
  background: #121212; /* Spotify's background color */
  color: #FFFFFF; /* Spotify's text color */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

#root, .App {
  display: flex;
  flex-direction: column;
  flex: 1; /* Ensures that the app container does not exceed the viewport */
}

.container {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  margin: auto;
  max-width: 1200px; /* Max width can vary as per design requirements */
  padding: 20px;
  flex: 1; /* Allow container to grow and fill available space, accounting for header/footer */
  overflow: auto; /* Scroll inside container if content overflows */
}

main {
  flex: 1; /* Allow main content to grow and use available space */
  padding: 20px;
  background: #181818; /* Spotify's card background color */
  border-radius: 8px; /* Rounded corners for the main content */
  margin: 20px; /* Spacing around the main content */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5); /* Adding depth with shadow */
  overflow: auto; /* Scroll inside main if content overflows */
}

.footer {
  background-color: #181818; /* Footer background to match Spotify's theme */
  color: #FFFFFF; /* Footer text color */
  text-align: center;
  padding: 10px 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation */
  border-top: 1px solid #282828; /* Subtle border for the top edge of the footer */
}

.navbar {
  background-color: #181818;
  color: #FFFFFF;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.navLinks {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.navLinks li a {
  color: #FFFFFF;
  text-decoration: none;
  padding: 10px 15px; /* Padding for clickable area */
  border-radius: 500px; /* Rounded pill shape for links */
}

.navLinks li a:hover, .navLinks li a:focus {
  background-color: #282828; /* Subtle hover/focus state */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 10px; /* Less padding on smaller screens */
  }

  main {
    margin: 10px; /* Less margin on smaller screens */
  }

  .navbar, .footer {
    padding: 5px 10px; /* Adjust padding for smaller nav and footer */
  }

  .navLinks {
    flex-direction: column; /* Stack nav links vertically on small screens */
  }
}
