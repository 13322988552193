/* index.css */
body {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Spotify's primary font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212; /* Spotify's background color */
  color: #FFFFFF; /* Spotify's text color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #1DB954; /* Spotify's brand color for accenting code elements if needed */
}

/* Scrollbar styles for Webkit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #0D0D0D; /* Color of the track, slightly lighter than body bg */
}

::-webkit-scrollbar-thumb {
  background-color: #282828; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners on the thumb */
  border: 3px solid #0D0D0D; /* Creates padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #3e3e3e; /* Color of the thumb on hover */
}
