/* In your modal's CSS file */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: #333; /* or any color that contrasts with the overlay */
  padding: 20px;
  border-radius: 8px;
  width: auto; /* or any specific width */
  max-width: 90%; /* Ensures that the modal is responsive */
}