/* LoadingIndicator.module.css */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: None;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.546); 
    border-top: 4px solid #fff; 
    border-radius: 50%; 
    width: 40px;
    height: 40px;
    animation: spin 3s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.loadingText {
  margin-top: 10px; 
}