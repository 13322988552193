.tradingSignalsContainer {
    background-color: #181818;
    color: #FFFFFF ; 
    padding: 20px;
    border-radius: 4px; 
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5); 
    overflow-x: auto; 
    overflow-y: hidden;
  }
  
  .signalHeader {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    background-color: #333; 
    padding: 10px 0; 
    font-weight: bold; 
    border-bottom: 2px solid #555; 
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  

  .signalRow {
      display: grid;
      grid-template-columns: repeat(4, 1fr); 
      padding: 10px 0; 
      border-bottom: 1px solid #555; 
  }

  .timeframe {
      display: flex;
      justify-content: center; 
      align-items: center; 
  }

  .indicator {
      display: flex;
      justify-content: center; 
      align-items: center;
  }
  
  .addButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .indicator img, .addButton img {
    width: 20px;
    height: 20px;
  }
  
  @media (max-width: 768px) {
    .tradingSignalsContainer {
      width: 100%; 
    }
  }
  
